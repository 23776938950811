export const en = {
    generic: {
        or: 'or'
    },
    navbar: {
        pricing: 'Pricing',
        features: 'Features',
        faq: 'FAQ',
        user: {
            profile: {
                title: 'Profile',
                edit: 'Edit profile',
            },
            manageSubscription: 'Manage subscription',
        },
    },
    homepage: {
        heading_1: 'Create a',
        heading_2: 'customised page',
        heading_3: 'in minutes',
        description: 'Bring your memories to life and create lasting memories for posterity',
        features: {
            title: 'Our features',
            description: 'We are constantly working to expand this list and ensure that your user experience on our platform is as pleasant as possible',
            feature_list: {
                item_1: {
                    title: 'Personalised memory pages', // Each page should be unique
                    description: 'Create unique pages to record personal experiences and achievements',
                },
                item_2: {
                    title: 'QR code accessibility', // Page is easily accessible via a QR code
                    description: 'Each page is accessible via a QR code for easy access to the memories.',
                },
                item_3: {
                    title: 'Personalised platform', // The page should be able to reflect the personality of the person
                    description: 'Offer a personalised platform for the permanent storage of memories.',
                },
                item_4: {
                    title: 'Picture and description', // You can also add pictures in addition to the biography (the lifeline is missing)
                    description: 'Add a picture of the person and a personal description to complete the pages',
                },
                item_5: {
                    title: 'Security', // The site is secure
                    description: 'Ensure the security of stored memories through modern encryption technologies',
                },
                item_6: {
                    title: 'Permanent availability', // Always online
                    description: 'Guarantee the permanent availability of the created memory pages for future generations',
                },
                item_7: {
                    title: 'Accessible anywhere', // Accessible from anywhere in the world.
                    description: 'Access the product from anywhere in the world to relive your memories anytime, anywhere',
                },
            },
        },
        pricing: {
            title: 'Prices',
            description: 'Whether you just want to try out our service or need more, we are at your disposal in both cases',
            free: {
                title: 'Free',
                description: 'For everyone',
                quote: 'To test the application and build trust',
                features: {
                    pageLimit: {
                        text: 'One page',
                        footnote: 'The maximum number of pages you can create.',
                    },
                    dataLimit: {
                        text: '2MB file size limit',
                        footnote: 'The maximum size of images that can be uploaded',
                    },
                    design: {
                        text: 'User-friendly interface',
                        footnote: 'Lorem Ipsum', // Todo: Must still be added
                    },
                    prioritySupport: {
                        text: 'Prioritized customer service',
                        negative: true,
                    },
                },
            },
            pro: {
                title: 'Pro',
                description: 'For larger projects with higher needs', // Todo: Still needs to be adjusted
                quote: 'For people who have experienced a lot and have a lot to tell',
                comingSoon: 'Coming soon',
                features: {
                    pageLimit: {
                        text: '5 pages',
                        footnote: 'The maximum number of pages you can create',
                    },
                    dataLimit: {
                        text: '5MB file size limit',
                        footnote: 'The maximum size of images that can be uploaded',
                    },
                    lifeline: {
                        text: 'Lifeline',
                        footnote: 'Lorem Ipsum', // Todo: Must still be added
                    },
                    design: {
                        text: 'User-friendly interface',
                    },
                    prioritySupport: {
                        text: 'Prioritized customer service',
                    },
                },
            },
            perMonth: 'Per month',
        },
        faq: {
            reasonOfUse: {
                title: 'Why should you use us?',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad architecto' +
                    ' consectetur culpa deleniti ducimus, eveniet illum inventore laborum minus quia ratione sint' +
                    ' suscipit tempora veniam? Dolorum nemo officia optio.',
            },
            reasonToTrust: {
                title: 'Why should you trust us?',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad architecto' +
                    ' consectetur culpa deleniti ducimus, eveniet illum inventore laborum minus quia ratione sint' +
                    ' suscipit tempora veniam? Dolorum nemo officia optio.', // TODO: Revise -> Incorporate that the application is secure and actively developed.
            },
            reasonToChoose: {
                title: 'Why should you choose us specifically?',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad architecto' +
                    ' consectetur culpa deleniti ducimus, eveniet illum inventore laborum minus quia ratione sint' +
                    ' suscipit tempora veniam? Dolorum nemo officia optio.',
            },
            questionsRemain: {
                title: 'Do you have any remaining questions?',
                description: 'If you have any further questions, please do not hesitate to contact us.',
            },
        },
    },
    profile: {
        generalInformation: {
            title: 'Profile information',
            description: 'Update the profile information and email address of your account.',
            firstname: 'First name',
            lastname: 'Last name',
            email: {
                title: 'Email',
                notVerified: 'Your email address is not verified.',
                resendButton: 'Click here to resend the verification email.',
                linkSent: 'A new verification link has been sent to your email address.',
            },
        },
        changePassword: {
            title: 'Update password',
            description: 'Make sure your account uses a long, random password to be secure.',
            currentPassword: 'Current password',
            newPassword: 'New password',
            confirmPassword: 'Confirm your password',
        },
        oauth: {
            title: 'Social media connections',
            missingInfos: 'Not all information was taken from your profile. Please complete the empty fields',
        },
        deleteAccount: {
            title: 'Delete account',
            description: 'Once your account is deleted, all resources and data will be permanently deleted. Before' +
                ' you delete your account please download all data and information that you wish to keep.',
            confirmation: {
                title: 'Are you sure you want to delete your account?',
                description: 'Once your account is deleted, all data will be permanently deleted.' +
                    ' Please enter your email to confirm that you wish to permanently delete your account.',
            },
        },
    },
    dashboard: {
        title: 'Dashboard',
        posts: 'My posts',
        add: 'Add',
        memory: {
            created_at: 'Created at',
            updated_at: 'Modified at',
        },
    },
    buttons: {
        login: 'Log in',
        logout: 'Log out',
        startNow: 'Start now',
        upgradeNow: 'Upgrade now',
        register: 'Register',
        dashboard: 'Dashboard',
        delete: 'Delete',
        deleteAccount: 'Delete account',
        abort: 'Cancel',
        save: 'Save',
        back: 'Back',
        retry: 'Try again',
        resetPassword: 'Reset password',
        add: 'Add',
        sendEmail: 'Send an email!',
    },
    auth: {
        email: 'E-Mail',
        password: 'Password',
        repeatPassword: 'Repeat password',
        rememberMe: 'Remember login',
        forgotPassword: {
            title: 'Forgot your password',
            description: 'Forgot your password? No problem! Just tell us your e-mail address and we will send you a reset, ' +
                'which you can use to choose a new password.,',
        },
        noUser: 'No account yet?',
        alreadyHasUser: 'Already own an account?',
        verified: 'Account successfully verified',
        loginForm: {
            login: 'Sign In',
        },
    },
    register: {
        title: 'Register',
        firstname: 'First name',
        lastname: 'Last name',
        confirmPassword: 'Confirm password',
        alreadyRegistered: 'Already registered?',
    },
    verifyEmail: {
        description: 'Thank you for signing up! Before you start, you could verify your email address by clicking on the link we ' +
            'have just sent you by e-mail? If you have not received the e-mail we will be happy to send you a new one',
        resendEmail: 'Resend verification email',
        linkSent: 'A new verification link has been sent to the e-mail address that you entered during registration',
    },
    form: {
        title: {
            label: 'Title',
            placeholder: 'Your heading',
        },
        text: {
            label: 'Content',
            placeholder: 'What would you like to enter?',
        },
        date: {
            label: 'Date',
            placeholder: 'Select a date',
        },
    },
    memory: {
        biography: 'Biography',
        events: {
            title: 'Lifeline',
            description: 'Here are moments worthy of naming',
            form: {
                add: {
                    title: 'Add Event',
                    description: 'Add an event', // TODO: refactor
                },
                edit: {
                    title: 'Edit Event',
                    description: 'Edit an Event',
                },
                delete: {
                    title: 'Are you sure you want to delete this Event?',
                    description: 'Do you really want to delete this event? This step can no longer be reversed.',
                },
                remainingChars: '{{ chars }} characters remaining',
            },
        },
        delete: {
            title: 'Do you really want to delete this memory?',
            description: 'Do you really want to delete this memory? This step is irreversible.',
        },
        protect: {
            title: 'Protect memory',
            description: 'Protect your memory with a password. Only people who have this password can access this page',
            cancel: 'Cancel protection',
            activate: 'Activate protection',
        },
        downloadError: 'There was a problem trying to download the QR code',
        emptyTitle: 'Pretty empty here',
        emptyDescription: 'Create your first post',
    },
    verifyPassword: {
        title: 'Enter password',
        description: 'This reminder has been password protected. Please enter the correct password to access this reminder',
        authenticate: 'Authenticate',
    },
    apiResponse: {
        success: {
            text: 'Your changes have been successfully applied',
            add: 'Your entries have been saved successfully',
        },
        error: {
            title: 'Uh oh! Something went wrong.',
            media: {
                maxReached: 'The maximum number of permitted media ({{ amount }}) has been reached.', // TODO: Überarbeiten
            },
        },
    },
    empty: {
        title: 'Pretty empty here',
        description: 'Create your first post.',
        descriptionNotOwner: 'No events have been added yet',
    },
    footer: {
        cookiePolicy: 'Cookie Policy',
        conditions: 'Terms and Conditions',
        dataProtection: 'Data protection',
        imprint: 'Imprint',
    },
}
