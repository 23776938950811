import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {de} from './langs/de.jsx'
import {en} from './langs/en.jsx'

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: 'en',
      detection: options,
      react: { useSuspense: false },
      resources: {
        de: {
          translation: de,
        },
        en: {
          translation: en,
        },
      },
    })

export default i18n
