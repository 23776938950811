import './bootstrap';
import '../css/app.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from "@sentry/react";
import './lib/i18n.jsx';

const appName = import.meta.env.VITE_APP_NAME || 'reminara';

createInertiaApp({
    title: () => appName,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: import.meta.env.SENTRY_LARAVEL_DSN, //  Capture 100% of the transactions
            tracePropagationTargets: [/^https:\/\/reminara\.de/, /^https:\/\/reminara\.com/],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        const root = createRoot(el);
        root.render(<App {...props} />);
    },
    progress: {
        color: '#4B5563',
    },
});
